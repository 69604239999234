<template>
    <AppHead :title="title" />

    <div id="mainContainer" class="relative flex h-screen flex-col overflow-auto bg-gray-50">
        <!-- Navigation -->
        <header
            :class="[
                theme == 'light' ? 'bg-white text-gray-900' : 'bg-transparent text-white hover:text-gray-100',
                'absolute inset-x-0 top-0 z-50',
            ]"
        >
            <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div class="flex lg:flex-1">
                    <Link :href="route('home')" class="-m-1.5 p-1.5">
                        <span class="sr-only">{{ app.name }}</span>
                        <img src="../../images/logo.svg" class="h-10 w-auto" />
                    </Link>
                </div>
                <div class="flex lg:hidden">
                    <button
                        type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                        @click="mobileMenuOpen = true"
                    >
                        <span class="sr-only">Open main menu</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                            />
                        </svg>
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <template v-for="item in navigation" :key="item.name">
                        <a
                            v-if="item.is_external"
                            :href="item.href"
                            target="_blank"
                            class="text-sm font-semibold leading-6"
                        >
                            {{ item.name }}
                        </a>
                        <Link v-else :href="item.href" class="text-sm font-semibold leading-6">
                            {{ item.name }}
                        </Link>
                    </template>
                </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a :href="app.locales.href" class="text-sm font-medium">
                        {{ app.locales.label }}
                    </a>
                </div>
            </nav>
            <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50" />
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                >
                    <div class="flex items-center justify-between">
                        <Link :href="route('home')" class="-m-1.5 p-1.5">
                            <span class="sr-only">{{ app.name }}</span>
                            <img src="../../images/logo.svg" class="h-10 w-auto" />
                        </Link>
                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <Link
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {{ item.name }}
                                </Link>
                            </div>
                            <div class="py-6">
                                <a
                                    :href="app.locales.href"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {{ app.locales.label }}
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <div class="flex-grow">
            <slot />
        </div>

        <AppFooter class="flex-shrink-0" />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import AppHead from '@/Layouts/Partials/Head.vue';
import AppFooter from '@/Layouts/Partials/Footer.vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { translate as t } from '@/i18n';

const props = defineProps({
    title: String,
    theme: {
        type: String,
        default: 'light',
    },
});

const mobileMenuOpen = ref(false);

const app = computed(() => usePage().props.app);

const navigation = [
    {
        name: t('navigation.about'),
        href: route('about-us'),
    },
    {
        name: t('navigation.franchising'),
        href: route('franchising'),
    },
    {
        name: t('navigation.training'),
        href: 'https://alberta.college/skillsbuild/',
        is_external: true,
    },
    {
        name: t('navigation.partnership'),
        href: '#',
    },
    {
        name: t('navigation.book_a_all'),
        href: 'https://outlook.office365.com/book/FranchiseurMeeting@franchiseur.ca/',
        is_external: true,
    },
];
</script>
